import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Card from "../components/card";
import Searchbar from "../components/searchbar";

export const query = graphql`
	query Category($id: String!) {
		recipes: allStrapiRecipe(
			filter: {
				categories: { elemMatch: { id: { eq: $id } } }
				Publish: { eq: true }
			}
			sort: { order: [DESC, DESC], fields: [Sorting, updatedAt] }
		) {
			edges {
				node {
					Name
					Description
					Coverphoto {
						publicURL
					}
					Slug
					styles {
						Name
						id
						Slug
					}
					categories {
						Name
						id
						Slug
					}
					strapiId
				}
			}
		}
		category: strapiCategory(strapiId: { eq: $id }) {
			Name
		}
	}
`;

const Category = ({ data }) => {
	const recipes = data.recipes.edges;
	const category = data.category.Name;

	return (
		<Layout 
			title={category}的食譜
			description={category}的食譜
			>
			<section className="py-5">
				<div className="flex flex-col-reverse lg:flex-row px-3 lg:px-10">
					<div className="lg:w-3/4">
						<h1 className="pt-5 lg:pt-0 text-3xl lg:text-3xl">
							{category}的食譜
						</h1>
					</div>
					<div className="item-right lg:w-1/4">
						<Searchbar />
					</div>
				</div>
			</section>
			<section>
				<div className="flex flex-col lg:flex-row lg:flex-wrap container mx-auto lg:py-8 justify-center">
					{recipes.map((recipe, i) => {
						return <Card recipe={recipe} />;
					})}
				</div>
			</section>
		</Layout>
	);
};

export default Category;
