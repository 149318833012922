import React from "react";
import { Link } from "gatsby";

const Card = ({ recipe }) => {
	return (
		<div
			id="recipe-card"
			className="max-w-sm rounded-md shadow-lg mx-3 mb-10 lg:w-1/3"
		>
			<Link to={`/recipe/${recipe.node.Slug}`} className="no-underline">
				<div
					className="h-56 rounded-t-md bg-cover bg-center bg-no-repeat"
					style={{
						backgroundImage: `url(${recipe.node.Coverphoto.publicURL})`,
					}}
				></div>
			</Link>
			<div className="px-6 py-4">
				<div className="inline-block px-2 py-1 mb-2 font-bold text-xl bg-yellow-cust1">
					<Link
						to={`/recipe/${recipe.node.Slug}`}
						className="no-underline"
					>
						{recipe.node.Name}
					</Link>
				</div>
				<p className="text-gray-700 text-base"></p>
			</div>
			<div className="px-6 py-6">
				{recipe.node.categories.map((cat) => (
					<Link to={`/category/${cat.Slug}`}>
						<span className="inline-block border border-yellow-cust1 rounded-md px-3 py-1 mx-1 text-gray-700 text-sm">
							#{cat.Name}
						</span>
					</Link>
				))}
				{recipe.node.styles.map((style) => (
					<Link to={`/style/${style.Slug}`}>
						<span className="inline-block bg-yellow-cust2 rounded-md px-3 py-1 mx-1 text-gray-700 text-sm">
							#{style.Name}
						</span>
					</Link>
				))}
			</div>
		</div>
	);
};

export default Card;
